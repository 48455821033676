export const accountColors = [
  '#1E88E5', // Blue
  '#008080', // Green
  '#F4511E', // Orange
  '#8E24AA', // Purple
  '#E53935', // Red
  '#FB8C00', // Yellow
  '#3949AB', // Indigo
  '#00ACC1', // Cyan
];

export const RecurringExpenses: { category: string; expenses: string[]; percentage: string }[] = [
  {
    category: 'None',
    expenses: [],
    percentage: '0',
  },  
  {
    category: 'Housing',
    expenses: [
      'Rent',
      'Mortgage',
      'Homeowners Insurance',
      'Property Taxes',
    ],
    percentage: '.3',
  },
  {
    category: 'Utilities',
    expenses: [
      'Electricity Bill',
      'Gas Bill',
      'Water Bill',
      'Sewer Bill',
      'Trash Collection',
      'Internet Service',
      'Cable TV',
      'Cell Phone Bill',
      'Landline Phone',
    ],
    percentage: '.1',
  },
  {
    category: 'Entertainment',
    expenses: [
      'Netflix',
      'Amazon Prime',
      'Disney+',
      'HBO Max',
      'Spotify',
      'Apple Music',
      'Date Nights',
      'Movie Tickets',
    ],
    percentage: '.05',
  },
  {
    category: 'Food',
    expenses: [
      'Groceries',
    ],
    percentage: '.15',
  },
  {
    category: 'Transportation',
    expenses: [
      'Car Payment',
      'Car Insurance',
      'Car Maintenance',
      'Public Transportation',
      'Ride-Sharing',
    ],
    percentage: '.15',
  },
  {
    category: 'Healthcare',
    expenses: [
      'Health Insurance',
      'Doctor Visits',
      'Prescriptions',
      'Dental Care',
      'Vision Care',
    ],
    percentage: '.1',
  },
  {
    category: 'Fitness',
    expenses: [
      'Gym Membership',
    ],
    percentage: '.02',
  },
  {
    category: 'Insurance',
    expenses: [
      'Life Insurance',
      'Disability Insurance',
      'Long-term Care Insurance',
    ],
    percentage: '.05',
  },
  {
    category: 'Clothing and Personal Care',
    expenses: [
      'Clothing',
      'Shoes',
      'Accessories',
      'Laundry',
      'Dry Cleaning',
      'Haircuts',
      'Salon Services',
      'Cosmetics',
      'Toiletries',
    ],
    percentage: '.05',
  },
  {
    category: 'Childcare and Education',
    expenses: [
      'Childcare',
      'Tuition',
      'School Supplies',
      'Extracurricular Activities',
      'College Savings',
    ],
    percentage: '.1',
  },
  {
    category: 'Debt and Savings',
    expenses: [
      'Student Loan Payments',
      'Personal Loan Payments',
      'Credit Card Payments',
      'Retirement Savings',
      'Emergency Fund',
      'Investments',
    ],
    percentage: '.15',
  },
  {
    category: 'Banking and Financial Services',
    expenses: [
      'Bank Fees',
      'Identity Theft Protection',
      'Taxes',
      'Legal Fees',
      'Financial Advisor',
    ],
    percentage: '.02',
  },
  {
    category: 'Charity and Gifts',
    expenses: [
      'Charitable Donations',
      'Religious Contributions',
      'Gifts',
    ],
    percentage: '.05',
  },
  {
    category: 'Subscriptions/Memberships',
    expenses: [
      'Magazines',
      'Newspapers',
      'Professional Association Memberships',
      'Club Memberships',
    ],
    percentage: '.02',
  },
  {
    category: 'Pets',
    expenses: [
      'Pet Food',
      'Pet Supplies',
      'Veterinary Care',
      'Grooming',
      'Pet Insurance',
    ],
    percentage: '.03',
  },
  {
    category: 'Miscellaneous',
    expenses: [
      'Parking Fees',
      'Tolls',
      'House Cleaning Services',
      'Yard Maintenance',
      'Home Repairs',
      'Home Security',
      'Storage Unit Rental',
    ],
    percentage: '.05',
  },
];

export const FutureHolidays = [
  {
    name: 'New Year\'s Day',
    next_occurrence: ['2024-01-01'],
  },
  {
    name: 'Martin Luther King Jr. Day',
    next_occurrence: [
      '2024-01-15',
      '2025-01-20',
      '2026-01-19',
      '2027-01-18',
      '2028-01-17',
      '2029-01-15',
      '2030-01-21',
      '2031-01-20',
      '2032-01-19',
    ],
  },
  {
    name: 'Groundhog Day',
    next_occurrence: ['2024-02-02'],
  },
  {
    name: 'Valentine\'s Day',
    next_occurrence: ['2024-02-14'],
  },
  {
    name: 'Presidents\' Day',
    next_occurrence: [
      '2024-02-19',
      '2025-02-17',
      '2026-02-16',
      '2027-02-15',
      '2028-02-21',
      '2029-02-19',
      '2030-02-18',
      '2031-02-17',
      '2032-02-16',
    ],
  },
  {
    name: 'St. Patrick\'s Day',
    next_occurrence: ['2024-03-17'],
  },
  {
    name: 'April Fools\' Day',
    next_occurrence: ['2024-04-01'],
  },
  {
    name: 'Earth Day',
    next_occurrence: ['2024-04-22'],
  },
  {
    name: 'Arbor Day',
    next_occurrence: [
      '2024-04-26',
      '2025-04-25',
      '2026-04-24',
      '2027-04-30',
      '2028-04-28',
      '2029-04-27',
      '2030-04-26',
      '2031-04-25',
      '2032-04-30',
    ],
  },
  {
    name: 'Cinco de Mayo',
    next_occurrence: ['2024-05-05'],
  },
  {
    name: 'Mother\'s Day',
    next_occurrence: [
      '2024-05-12',
      '2025-05-11',
      '2026-05-10',
      '2027-05-09',
      '2028-05-14',
      '2029-05-13',
      '2030-05-12',
      '2031-05-11',
      '2032-05-09',
    ],
  },
  {
    name: 'Armed Forces Day',
    next_occurrence: [
      '2024-05-18',
      '2025-05-17',
      '2026-05-16',
      '2027-05-15',
      '2028-05-20',
      '2029-05-19',
      '2030-05-18',
      '2031-05-17',
      '2032-05-15',
    ],
  },
  {
    name: 'Memorial Day',
    next_occurrence: [
      '2024-05-27',
      '2025-05-26',
      '2026-05-25',
      '2027-05-31',
      '2028-05-29',
      '2029-05-28',
      '2030-05-27',
      '2031-05-26',
      '2032-05-31',
    ],
  },
  {
    name: 'Flag Day',
    next_occurrence: ['2024-06-14'],
  },
  {
    name: 'Juneteenth',
    next_occurrence: ['2023-06-19'],
  },
  {
    name: 'Father\'s Day',
    next_occurrence: ['2023-06-18'],
  },
  {
    name: 'Independence Day',
    next_occurrence: ['2023-07-04'],
  },
  {
    name: 'Parents\' Day',
    next_occurrence: ['2023-07-23'],
  },
  {
    name: 'Coast Guard Day',
    next_occurrence: ['2023-08-04'],
  },
  {
    name: 'Labor Day',
    next_occurrence: [
      '2023-09-04',
      '2024-09-02',
      '2025-09-01',
      '2026-09-07',
      '2027-09-06',
      '2028-09-04',
      '2029-09-03',
      '2030-09-02',
      '2031-09-01',
      '2032-09-07',
    ],
  },
  {
    name: 'Patriot Day',
    next_occurrence: ['2023-09-11'],
  },
  {
    name: 'Constitution Day',
    next_occurrence: ['2023-09-17'],
  },
  {
    name: 'Grandparents\' Day',
    next_occurrence: ['2023-09-10'],
  },
  {
    name: 'Indigenous Peoples\' Day',
    next_occurrence: [
      '2023-10-09',
      '2024-10-14',
      '2025-10-13',
      '2026-10-12',
      '2027-10-11',
      '2028-10-09',
      '2029-10-08',
      '2030-10-14',
      '2031-10-13',
      '2032-10-11',
    ],
  },
  {
    name: 'Columbus Day',
    next_occurrence: [
      '2023-10-09',
      '2024-10-14',
      '2025-10-13',
      '2026-10-12',
      '2027-10-11',
      '2028-10-09',
      '2029-10-08',
      '2030-10-14',
      '2031-10-13',
      '2032-10-11',
    ],
  },
  {
    name: 'Halloween',
    next_occurrence: ['2023-10-31'],
  },
  {
    name: 'Veterans Day',
    next_occurrence: ['2023-11-11'],
  },
  {
    name: 'Thanksgiving Day',
    next_occurrence: [
      '2023-11-23',
      '2024-11-28',
      '2025-11-27',
      '2026-11-26',
      '2027-11-25',
      '2028-11-23',
      '2029-11-29',
      '2030-11-28',
      '2031-11-27',
      '2032-11-25',
    ],
  },
  {
    name: 'Black Friday',
    next_occurrence: [
      '2023-11-24',
      '2024-11-29',
      '2025-11-28',
      '2026-11-27',
      '2027-11-26',
      '2028-11-24',
      '2029-11-30',
      '2030-11-29',
      '2031-11-28',
      '2032-11-26',
    ],
  },
  {
    name: 'Cyber Monday',
    next_occurrence: [
      '2023-11-27',
      '2024-12-02',
      '2025-12-01',
      '2026-11-30',
      '2027-11-29',
      '2028-11-27',
      '2029-12-03',
      '2030-12-02',
      '2031-12-01',
      '2032-11-29',
    ],
  },
  {
    name: 'Pearl Harbor Remembrance Day',
    next_occurrence: ['2023-12-07'],
  },
  {
    name: 'Christmas Eve',
    next_occurrence: ['2023-12-24'],
  },
  {
    name: 'Christmas Day',
    next_occurrence: ['2023-12-25'],
  },
  {
    name: 'Kwanzaa',
    next_occurrence: ['2023-12-26'],
  },
  {
    name: 'New Year\'s Eve',
    next_occurrence: ['2023-12-31'],
  },
];
